<template>
  <!-- Coming soon page-->
  <b-overlay
    :show="showLoading"
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <b-img
          fluid
          :src="require('@/assets/images/pages/doctors-prescription-image-family.jpg')"
          alt="Coming soon page"
        />
        <div class="w-100 text-center">
          <h3 class="font-weight-bolder">
            Nice to have you on board!
          </h3>
          <h4
            class="h1"
            :class="{'display-4': $store.getters['app/currentBreakPoint'] !== 'xs'}"
          >
            Welcome to Medknit
          </h4>
          <h4 class="font-weight-normal">
            We will be in touch
          </h4>
          <div class="mt-3">
            <medknit-logo />
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BImg } from 'bootstrap-vue'
import MedknitLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BOverlay,
    BImg,
    MedknitLogo,
  },
  data() {
    return {
      showLoading: false,
    }
  },
  created() {
    this.showLoading = true
    this.$store.dispatch('comingSoon/verifyPotentialUser', this.$route.query.email_address)
      .then(() => {
        this.$bvToast.toast('Your email is verified', {
          title: 'Registration Successful',
          variant: 'primary',
          solid: true,
          autoHideDelay: 10000,
        })
        this.showLoading = false
      })
      .catch(() => {
        this.$bvToast.toast('Failed to verify your email', {
          title: 'Registration Failure',
          variant: 'danger',
          solid: true,
          autoHideDelay: 10000,
        })
        this.showLoading = false
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
